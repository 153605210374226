


































import { SfButton, SfSearchBar, SfOverlay } from '@storefront-ui/vue';
import {
  defineComponent, ref, watch, useRoute, useFetch, useRouter, useContext,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import SvgImage from '~/components/General/SvgImage.vue';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { Products } from '~/modules/GraphQL/types';
import axios from 'axios';
import { useConfig } from '~/composables';
import app from '~/serverMiddleware/body-parser';
import type { Product } from '~/modules/catalog/product/types';
import { watchEffect } from '@vue/runtime-dom';

export default defineComponent({
  name: 'SearchBar',
  components: {
    SfSearchBar,
    SfButton,
    SvgImage,
    SfOverlay,
    SearchResults: () => import(
    /* webpackPrefetch: true */ '~/components/Header/SearchBar/SearchResults.vue'
    ),
  },
  directives: { clickOutside },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 999,
    },
    minTermLen: {
      type: Number,
      default: 3,
    },
    searchResults: {
      type: Array as PropType<Product[] | null>,
      default: () => [],
    },
  },
  emits: ['set-is-open', 'set-search-results'],
  setup(props, { emit }) {
    const term = ref('');
    const route = useRoute();

    const { getProductList } = useProduct();

    const { config } = useConfig();
    const title = ref<string>('');
    const hideTS = ref<boolean>(false);
    const router = useRouter();
    const { app } = useContext();

    const prohibitedWord = config.value.prohibited_word;
    const prohibitedContainWord = config.value.prohibited_contain_word;
    const isProhibitedWord = ref(false);
    const isProhibitedContainWord = ref(false);
    const searchBarElement = ref<HTMLElement | null>(null);

    useFetch(async () => {
      const baseUrl = config.value.secure_base_url;
      const rs = await axios.get(
        baseUrl + "rest/V1/utech-topsearch/topsearchlist",
        {
          headers: {
            Authorization: "Bearer " + process.env.MG_ACCESS_TOKEN,
          },
        }
      );
      const topsearchlist = JSON.parse(rs.data);

      title.value = topsearchlist.top_search.title;
    });

    const showSearch = () => {
      if (!props.isSearchOpen) {
        hideTS.value = true;
        emit('set-is-open', true);
        if (document) {
          document.body.classList.add('no-scroll');
        }
      }
    };

    const hideSearch = () => {
      if (props.isSearchOpen) {
        hideTS.value = false;
        emit('set-is-open', false);
        emit('set-search-results', null);
        if (document) {
          document.body.classList.remove('no-scroll');
        }
      }
    };

    const toggleSearch = () => {
      if (props.isSearchOpen) {
        hideSearch();
      } else {
        showSearch();
      }
    };

    const quickSearch = (text: string) => {
      handleKeydownEnter(text);
      hideSearch();
      showSearch();
    }

    const _quickSearch = (text: string) => {

      if (text !== '') {
        handleKeydownEnter(text);
        router.push(app.localeRoute({
          name: 'CatalogSearch',
          path: '/catalogsearch/result/',
          query: { q: text }
        }));
      }
    }

    const closeSearch = (event: MouseEvent) => {
      if (document) {
        const searchResultsEl = document.querySelector('.search');
        const closeTriggerElement = event.target as HTMLElement;

        if (!searchResultsEl?.contains(closeTriggerElement)) {
          hideSearch();
          term.value = '';
        }
      } else {
        hideSearch();
        term.value = '';
      }
    };

    const rawHandleSearch = async (searchTerm: string) => {
      term.value = searchTerm;
      if (term.value.length < props.minTermLen) return;

      // M2-579
      if (handleKeywordSearch(term.value)) {
        const productList: Products = await getProductList({
          pageSize: props.itemsPerPage,
          search: term.value,
        }) as unknown as Products;

        emit('set-search-results', productList?.items);
      } else {
        if (isProhibitedWord.value) {
          isProhibitedWord.value = false;
        }
        if (isProhibitedContainWord.value) {
          isProhibitedContainWord.value = false;
        }
      }
      return;
    };

    const debouncedHandleSearch = debounce(rawHandleSearch, 1000);

    const handleKeydownEnter = (searchTerm: string) => {
      // cancel debounce timeout started by typing into the searchbar - this is to avoid making two network requests instead of one
      debouncedHandleSearch.cancel();
      if (!isProhibitedWord.value) {
        rawHandleSearch(searchTerm);
      }
    };

    const handleKeywordSearch = (searchTerm: string) => {
      let allowisProhibitedWord = false;
      let allowisProhibitedContainWord = false;

      if (searchTerm) {
        if (Object.entries(prohibitedWord).length > 0) {
          for (const [key, value] of Object.entries(prohibitedWord)) {
            if (searchTerm === value.toString()) {
              isProhibitedWord.value = true;
            }
            if (parseInt(key) === Object.entries(prohibitedWord).length - 1) {
              allowisProhibitedWord = true;
            }
          }
        }

        if (Object.entries(prohibitedContainWord).length > 0) {
          for (const [key, value] of Object.entries(prohibitedContainWord)) {
            if (searchTerm.includes(value.toString())) {
              isProhibitedContainWord.value = true;
            }
            if (parseInt(key) === Object.entries(prohibitedContainWord).length - 1) {
              allowisProhibitedContainWord = true;
            }
          }
        }
      }

      if ((isProhibitedWord.value && allowisProhibitedWord) || (isProhibitedContainWord.value && allowisProhibitedContainWord)) {
        term.value = '';
        return false;
      }

      return true;
    };

    watch(route, () => {
      hideSearch();
      term.value = '';
    });

    return {
      closeSearch,
      showSearch,
      hideSearch,
      toggleSearch,
      rawHandleSearch,
      debouncedHandleSearch,
      handleKeydownEnter,
      term,
      title,
      quickSearch,
      hideTS,
      _quickSearch,
      handleKeywordSearch,
      searchBarElement
    };
  },
});

